import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { connect } from "react-redux"

const Contact = ({uri, location}) => (
  <Layout page="contact">
    <SEO title="Contact Us" />
    <div className="mb-2">
      <div className="container" style={{ position:"relative", color:'#666', marginBottom: `1.45rem` }}>
        <div id="content" className="col-8">
          
        <h2>About us</h2>

        <p>Jobsmigas.com is a web site that provides information of job vacancies with free that valid and verified . Jobsmigas.com online in Indonesia since 2011. Owned and made by Indonesian that aims to provide a useful contribution to the entire Indonesian professional. Our website is simple but responsive - reliable and better</p>

        <h2>Contact Information</h2>

        <dl className="icon-holder"> <dt><i class="fa fa-map-marker"></i></dt> <dd><strong>Jakarta</strong><br /> Equity Tower Lt.22, SCBD Jl. Jend Sudirman Kav 52-53 Jakarta Selatan 12190</dd> <dt><i class="fa fa-phone"></i></dt> <dd>T : 021 29035656</dd> <br /> <dl className="icon-holder"> <dt><i class="fa fa-map-marker"></i></dt> <dd><strong>Batam.</strong><br /> Ruko Green Land Blok J no.8 Batam Center - KEPRI</dd> <dt><i class="fa fa-phone"></i></dt> <dd>Telp. : 0778 480 7196</dd> <dt><i class="fa fa-envelope"></i></dt> <dd> <a href="mailto:info@jobsmigas.co.id">info@jobsmigas.co.id</a> </dd> </dl>
         </dl>

        </div>
      </div>
    </div>
  </Layout>
)

const mapStateToProps = state => ({
    auth:state.auth
  })

export default connect(mapStateToProps, {})(Contact)
